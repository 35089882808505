<template>
  <div class="teamQueryContainers">
    <div class="aboutUsWid centerAlign">
      <div class="aboutUsBox">
        <div class="aboutUsTitle">团队查询</div>
        <div class="aboutUsTitle2">TEAM QUERY</div>
        <div class="aboutUsBackText">TEAM QUERY</div>
        <!-- 未查询 -->
        <div class="queryBox flex_between" v-if="queryState == 0">
          <div class="queryContainers">
            <div class="identifyingTag verticals">人员查询</div>
            <div class="searchContainers">
              <el-input class="searchVal" placeholder="请输入员工名字" v-model="queryVal" clearable></el-input>
              <div class="queryBut verticals" @click="inquireFun()">查询</div>
            </div>
          </div>
        </div>
        <!-- 已查询 -->
        <div class="queryBox flex_between" v-if="queryState != 0" style="overflow-x: scroll;white-space: nowrap;">
          <!-- 返回查询界面 -->
          <div class="getBackBut"  v-if="queryState != 0" @click="queryState = 0">返回查询</div>

          <div class="queryInfoBox centerAlign" v-for="(res) in staffList">
            <div style="width: 12rem;margin: 0 auto;">
              <div class="staffImg">
                <el-image fit="fill" :src="res.image" :preview-src-list="srcList" style="width: 100%;height: 100%;border: 0.5px solid #F08307;"></el-image>
              </div>
              <div class="staffName">{{res.name}}</div>
              <div class="staffText">{{staffText1}}{{res.jobNumber}}</div>
              <div class="staffText">{{staffText2}}{{res.post}}</div>
              <div class="staffText">{{staffText3}}{{res.area}}</div>
            </div>
          </div>
          <div class="noData verticals" v-if="staffList.length == 0">查无此人</div>
        </div>




      </div>
    </div>
  </div>
</template>

<script>
import { request } from '@/utils/request';
export default {
  name: 'teamQuery',
  components: {},
  data() {
    return {
      //查询状态0未查询，1已查询
      queryState: 0,
      //查询参数
      queryVal: "",
      staffText1:"工\u3000\u3000号：",
      staffText2:"职\u3000\u3000位：",
      staffText3:"所属区域：",
      //查询结果
      staffList: [],
      //图片预览
      srcList: []
    };
  },
  mounted() {},
  methods: {
    /** 人员查询 **/
    inquireFun(){
      this.queryVal = this.queryVal.replace(/\s*/g, "")
      if (this.queryVal.length < 1) {
          this.$message.warning('请输入员工名字')
          return
      }
      request('https://xinke.thinkervc.cn/api/applet/aersonnelSystem/getPersonnelSystemByName?name=' + this.queryVal,{},{method: 'get'}).then(res => {
        if (res.code === 200) {
          var list = res.data;
          this.staffList = list;
          this.queryState = 1;
          var img = [];
          for(var i=0; i < list.length; i++){
            img.push(list[i].image)
          }
          this.srcList = img;
        }
      });

    }
  }
};
</script>
<style lang="scss" scoped>
/* 去除默认样式 */
a {
  text-decoration: none;
  color: #333;
}
a:link {
  text-decoration: none;
}
a:visited {
  text-decoration: none;
}
a:hover {
  color: #f08519;
  text-decoration: none;
}
a:active {
  text-decoration: none;
}
.verticals{
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}
.transition{transition: .5s;-moz-transition: .5s;-webkit-transition: .5s;-o-transition: .5s;-ms-transition: .5s;}
/* 两端对齐，项目之间的间隔都相等 */
.flex_between {
	display: flex;
	justify-content: space-between;
}
.centerAlign{
	display: flex;
	align-items: center;
}
.line4{
	overflow:hidden;
	text-overflow:ellipsis;
	white-space: normal;
	display:-webkit-box;
	-webkit-box-orient:vertical;
	-webkit-line-clamp:4;
}
.teamQueryContainers{
  width: 100%;
  height: 100vh;
  background: #f8f9fc;
}
.aboutUsBox{
  width: 100%;
}
.aboutUsWid{
  width: 1200px;
  height: 100vh;
  padding-top: 60px;
  margin: 0 auto;
}
.aboutUsTitle{
  font-size: 1.7rem;
  font-family: Source Han Sans CN, Source Han Sans CN-Regular;
  font-weight: 400;
  text-align: center;
  color: #333333;
}
.aboutUsTitle2{
  font-size: 1.7rem;
  font-family: Source Han Sans CN, Source Han Sans CN-Bold;
  font-weight: 700;
  text-align: center;
  color: #333333;
}
.aboutUsBackText{
  height: 8rem;
  line-height: 8rem;
  opacity: 0.1;
  font-size: 6.81rem;
  font-family: Source Han Sans CN, Source Han Sans CN-Bold;
  font-weight: 700;
  text-align: center;
  color: rgba(240,131,7,0.70);
  margin-top: 1rem;
}
.queryBox{
  position: relative;
  width: 100%;
  height: 33.4rem;
  background: #ffffff;
  margin-top: -4rem;
  z-index: 2;
}
.queryContainers{
  width: 38rem;
  margin: 0 auto;
  padding-top: 8rem;
  overflow: hidden;
}
.identifyingTag{
  width: 9rem;
  height: 2.7rem;
  background: #f08307;
  border: 0.06rem solid #f08307;
  font-size: 1.13rem;
  font-family: Source Han Sans CN, Source Han Sans CN-Bold;
  font-weight: 700;
  text-align: center;
  color: #ffffff;
}
.searchContainers{
  display: flex;
  width: 38rem;
  height: 2.7rem;
  border: 0.5px solid #f08307;
}
.searchVal{
  width: 31rem;
}
::v-deep .el-input__inner{
  width: 31rem;
  // height: 2.6rem;
  border: 0;
}
.queryBut{
  width: 7rem;
  height: 2.7rem;
  background: #f08307;
  font-size: 1.13rem;
  font-family: Source Han Sans CN, Source Han Sans CN-Bold;
  font-weight: 700;
  color: #ffffff;
  cursor: pointer;
}
/**查询详情**/
.queryInfoBox{
  width: 14rem;
  height: 100%;
  margin: 0 auto;
  overflow: hidden;
}
.staffImg{
  width: 12rem;
  height: 13rem;
  padding: 0 1rem;
}
.staffName{
  height: 1.5rem;
  font-size: 1.5rem;
  font-family: Source Han Sans CN, Source Han Sans CN-Bold;
  font-weight: 700;
  text-align: center;
  color: #f08519;
  white-space: nowrap;
  line-height: 1.5rem;
  margin: 1rem 0 1.2rem 0;
}
.staffText{
  font-size: 1.25rem;
  font-family: Source Han Sans CN, Source Han Sans CN-Regular;
  font-weight: 400;
  text-align: left;
  color: #333333;
  margin-bottom: 1rem;
}
.getBackBut{
  position: absolute;
  top: 0;
  left: 0;
  background-color: #f08519;
  color: #ffffff;
  padding: 5px 10px;
  font-size: 14px;
  border-radius: 0 5px 10px 0;
  cursor: pointer;
  z-index: 99;
}
.noData{
  font-size: 3rem;
  font-family: Source Han Sans CN, Source Han Sans CN-Regular;
  font-weight: 400;
  text-align: center;
  color: #cecece;
  letter-spacing: 2px;
  position: relative;
  z-index: 11;
  width: 100%;
  height: 100%;
}
@media screen and (max-width: 1200px) {
  .aboutUsWid{
    width: 95%;
    padding: 0;
  }
  .queryBox{
    min-height: 33.4rem;
    display: block;
    height: auto;
  }
  .aboutUsBackText{
    font-size: 2.81rem;
    margin-top: 0;
    height: 6rem;
    line-height: 4rem;
  }
  .queryContainers{
    padding-top: 5rem;
    width: 100%;
  }
  .identifyingTag{
      width: 7rem;
      height: 2.3rem;
      font-size: 1.1rem;
  }
  .searchContainers{
    width: 100%;
  }
  .searchVal{
    width: 70%;
  }
  ::v-deep .el-input__inner{
    width: 70%;
  }
  .queryInfoBox{
    width: 100%;
    margin-top: 4rem;
  }
  .queryBut {
      width: 30%;
      height: 2.7rem;
      font-size: 1.1rem;
  }
}
</style>
